import {FEATURE_NAMES} from './growthbook/constants';
import {BOOKMARK_CUSTOM_EVENTS} from './modules/bookmarks/constants';
import {initCustomSlider} from './modules/custom-slider';
import {debounce} from './services/helpers';
import {disableScroll, enableScroll} from './services/scroll-helpers';

const headerSettings = {
  isBurgerOpen: false,
  isStickyHeaderEnabled: true
};
const TOP_BANNER_HEIGHT = 96;
const STICKY_HEADER_TIMEOUT = 16;
const isDesktop = window.Site.targeting.platform === 'desktop';

const $headerReducer = document.querySelector('.js-header-reducer');

function initStickyHeaderLogo(
  settings = {isBurgerOpen: false, isStickyHeaderEnabled: true},
  timeout = STICKY_HEADER_TIMEOUT
) {
  const $logo = document.querySelector('.js-header-logo-sticky');

  if (!$logo) {
    return;
  }

  const $pageTopPlaceholder = document.querySelector('.js-page-top');

  window.addEventListener('scroll', debounce(() => {
    if (!settings.isStickyHeaderEnabled) {
      return;
    }

    if (window.scrollY > $pageTopPlaceholder.offsetHeight) {
      $logo.classList.add('sticky');
    } else {
      $logo.classList.remove('sticky');
    }
  }, timeout));
}

function initHeaderMenu() {
  if (!isDesktop) {
    const $container = document.querySelector('.js-header-nav-slider-container');
    const $items = [...(document.querySelectorAll('.js-header-nav-slider-item') || [])];
    const $leftArrow = document.querySelector('.js-header-nav-left-arrow');
    const $rightArrow = document.querySelector('.js-header-nav-right-arrow');

    initCustomSlider({$container, $slides: $items, $leftArrow, $rightArrow});
  } else {
    const $headerMenu = document.querySelector('.js-header-nav-slider-container');
    const $headerMoreButton = document.querySelector('.js-header-more-button');

    if (!$headerMenu) {
      return;
    }

    if ($headerMenu.offsetWidth >= $headerMenu.scrollWidth) {
      $headerMenu.classList.add('visible');
      return;
    }
    $headerMoreButton.classList.add('visible');

    const $headerSubMenu = document.querySelector('.js-header-more-list');
    const $links = [...$headerMenu.querySelectorAll('.header__main-menu-links-item')];

    while ($headerMenu.offsetWidth < $headerMenu.scrollWidth) {
      const $lastElement = $links.pop();

      $headerSubMenu.appendChild($lastElement);
    }

    $headerMenu.classList.add('visible');
    $headerMoreButton.onclick = () => {
      $headerMoreButton.classList.toggle('open');
    };
    window.addEventListener('click', e => {
      if (!$headerMoreButton.contains(e.target)) {
        $headerMoreButton.classList.remove('open');
      }
    });
  }
}

function initBurgerMenu(settings = {isBurgerOpen: false, isStickyHeaderEnabled: true}) {
  const isMobile = window.matchMedia('(max-width: 1151px)').matches;
  const $headerBurgerButton = document.querySelector('.js-burger-button');
  const $burgerCloseIcon = document.querySelector('.js-burger-close-button');
  const $burgerOverlay = document.querySelector('.js-burger-overlay');
  const $burgerMenu = document.querySelector('.js-burger-menu');
  const $burgerButtons = [...document.querySelectorAll('.js-burger-collapse')];
  const $burgerContainer = document.querySelector('.js-burger-container');
  const openBookmarksElement = document.querySelector('.js-open-bookmarks-list');

  let shouldOpenBookmarks = false;

  if (!$headerBurgerButton || !$burgerCloseIcon || !$burgerMenu || !$burgerOverlay || !$burgerContainer) {
    return;
  }

  function toggleBurgerVisible() {
    settings.isBurgerOpen = !settings.isBurgerOpen;
    settings.isStickyHeaderEnabled = !settings.isBurgerOpen;

    if (isMobile && settings.isBurgerOpen && window.scrollY < TOP_BANNER_HEIGHT) {
      window.scrollTo(0, TOP_BANNER_HEIGHT);
      $headerReducer.classList.add('mobile__open');
    }

    if (settings.isBurgerOpen) {
      $burgerMenu.classList.add('open');
      $headerBurgerButton.classList.add('open');
      disableScroll();
      $burgerContainer.classList.add('open');
    } else {
      enableScroll();
      $burgerContainer.classList.remove('open');
      $headerReducer.classList.remove('mobile__open');
    }
  }

  openBookmarksElement?.addEventListener('click', () => {
    toggleBurgerVisible();

    shouldOpenBookmarks = true;
  });

  $burgerContainer.addEventListener('animationend', () => {
    if (!settings.isBurgerOpen) {
      $headerBurgerButton.classList.remove('open');
      $burgerMenu.classList.remove('open');

      if (shouldOpenBookmarks) {
        document.dispatchEvent(new CustomEvent(BOOKMARK_CUSTOM_EVENTS.OPEN_LIST));
        shouldOpenBookmarks = false;
      }
    }
  });

  function onHeaderButtonClick() {
    toggleBurgerVisible();
  }

  function onOverlayClick() {
    toggleBurgerVisible();
  }

  function onBurgerIconClick() {
    toggleBurgerVisible();
  }

  $headerBurgerButton.onclick = onHeaderButtonClick;
  $burgerOverlay.onclick = onOverlayClick;
  $burgerCloseIcon.onclick = onBurgerIconClick;

  if (!$burgerButtons.length) {
    return;
  }

  const $elementsToCollapse = $burgerButtons.map($button => ({
    button: $button,
    parent: $button.parentElement
  }));

  $elementsToCollapse.forEach($element => {
    $element.button.onclick = () => {
      $element.parent.classList.toggle('open');
    };
  });
}

function initHeaderLanguageLink () {
  const growthBookLinkList = window.growthBook?.getFeatureValue(
    FEATURE_NAMES.HEADER_LANGUAGE_LINK
  );

  const subdomain = window.location.host.split('.')[0];

  const subdomainLinks = growthBookLinkList?.[subdomain];

  if (!subdomainLinks) {
    return;
  }

  const pathname = window.location.pathname;

  const linkKey = Object.keys(subdomainLinks).find(linkPattern => {
    if (pathname === linkPattern) {
      return true;
    }

    const matchKey = window.location.pathname.split('/')[1];

    return linkPattern.includes(matchKey);
  });

  if (!linkKey) {
    return;
  }

  const languageLinkUrl = subdomainLinks[linkKey];

  if (languageLinkUrl) {
    const languageLinkElement = document.querySelector('.js-header-language-link');

    languageLinkElement.href = `${window.location.protocol}//${
        subdomain === 'www' ? 'kaz' : 'www'
    }.${window.location.hostname.split('.').slice(1).join('.')}${languageLinkUrl}`;
    languageLinkElement.text = subdomain === 'www' ? 'KZ' : 'RU';
    languageLinkElement.classList.remove('hidden');
  }
}

if (isDesktop) {
  initStickyHeaderLogo(headerSettings, STICKY_HEADER_TIMEOUT);
}

initHeaderMenu();
initBurgerMenu(headerSettings);
initHeaderLanguageLink();
