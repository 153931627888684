// animation tooltips
const BOOKMARKS_TOOLTIP_ANIMATION = 10000;
const OPENED_ANIMATION_STATE = 'opened';
const PULSE_ANIMATION_STATE = 'pulse';
const CLOSED_ANIMATION_STATE = 'closed';
// cookie names
const BOOKMARKS_SIDEBAR_TOOLTIP_COOKIE = 'bookmarks_sidebar_tooltip_cookie';
const BOOKMARKS_ARTICLE_TOOLTIP_COOKIE = 'bookmarks_article_tooltip_cookie';
// time for cookies
const THIRTY_DAYS = 30;
const THIRTY_DAYS_IN_HOURS = 24 * THIRTY_DAYS;
// local storage
const BOOKMARK_IDS = 'bookmark_ids';
const BOOKMARKS_DB = 'bookmarks_db';
const BOOKMARKS_STORE = 'bookmarks_store';
// custom events
const BOOKMARK_CUSTOM_EVENTS = {
  OPEN_LIST: 'BOOKMARK_CUSTOM_EVENTS:OPEN_LIST',
  CLOSE_LIST: 'BOOKMARK_CUSTOM_EVENTS:CLOSE_LIST',
  ADD: 'BOOKMARK_CUSTOM_EVENTS:ADD',
  REMOVE: 'BOOKMARK_CUSTOM_EVENTS:REMOVE'
};

export {
  BOOKMARKS_TOOLTIP_ANIMATION,
  OPENED_ANIMATION_STATE,
  PULSE_ANIMATION_STATE,
  CLOSED_ANIMATION_STATE,
  BOOKMARKS_SIDEBAR_TOOLTIP_COOKIE,
  BOOKMARKS_ARTICLE_TOOLTIP_COOKIE,
  THIRTY_DAYS_IN_HOURS,
  BOOKMARK_CUSTOM_EVENTS,
  BOOKMARK_IDS,
  BOOKMARKS_DB,
  BOOKMARKS_STORE
};
